import React from "react"
// import Seo from "gatsby-plugin-wpgraphql-seo"
import "twin.macro"
// import parse from "html-react-parser"
import Container from "../components/container"
// import Button from "../components/button"
import BackButton from "../components/back-button"
// import Months from "../components/events/months"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "twin.macro"
import parse from "html-react-parser"
import download from "../images/svgs/download.svg"
import Icon from "../components/icon"
import moment from "moment/moment"
import "moment/locale/de"
import "../css/gutenberg.css"
import Social from "../components/social"
// import { Head as HeadSeo } from "../components/head"
// import facebook from "@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg"
// import twitter from "@fortawesome/fontawesome-free/svgs/brands/twitter.svg"
// import shareNode from "@fortawesome/fontawesome-free/svgs/solid/share-nodes.svg"

const Event = ({
  data: {
    event: {
      title,
      content,
      excerpt,
      startDate,
      endDate,
      slug,
      venue,
      eventInfo: { info },
      protagonists,
      eventsCategories: { nodes: categories },
    },
    /**wp: {
      seo: {
        schema: { logo },
      },
    },**/
    /**site: {
      siteMetadata: { siteUrl },
    },**/
    /**seo,**/
  },
  location,
}) => {
  const together = protagonists?.together || []
  const moderation = protagonists?.moderation || []

  console.log('protagonists: ', protagonists)

  //const together = []
  //const moderation = []

  return (
    <>
      <Container>
        <div tw="mt-6 mb-11">
          <BackButton />
        </div>
        <div tw="mb-6 lg:mb-10">
          <h1 tw="max-w-3xl text-3xl font-bold lg:text-5xl">{title}</h1>
          {categories && categories.length > 0 && (
            <h2 tw="mt-1 max-w-3xl font-regular text-xl">
              {categories.map(({ name }, index) => (
                <span key={`category-${index}`} tw="mr-4">
                  {name}
                </span>
              ))}
            </h2>
          )}
        </div>
        <div tw="grid grid-cols-12 gap-5">
          <div tw="col-span-12 -mx-4 bg-sand-500 px-4 py-8 sm:-mx-10 sm:px-10 lg:col-span-2 lg:mx-0 lg:bg-transparent lg:px-0 lg:pb-0 lg:pt-1.5">
            <dl tw="text-sm">
              <dt tw="font-bold">{moment(startDate).format("DD.MM.YYYY")}</dt>
              <dd tw="mb-5">
                {moment(startDate).format("HH:mm")} - ca.{" "}
                {moment(endDate).format("HH:mm")} Uhr, Berlin
              </dd>
              {info && (
                <>
                  <dt tw="font-bold"></dt>
                  <dd tw="mb-5">{info}</dd>
                </>
              )}
              <dt tw="font-bold">Kalender</dt>
              <dd tw="flex space-x-8 pt-1 lg:flex-col lg:space-x-0 lg:space-y-6">
                <a
                  href={`webcal://${location.host}/ical/${slug}.ics`}
                  tw="flex items-center font-bold no-underline decoration-anthracite-500 hover:underline hover:underline-offset-6 hover:decoration-3"
                  tabIndex={0}
                  target="_blank"
                  rel="noopener noreferrer nofollow noindex"
                >
                  <Icon title="Download" svg={download} tw="w-6" />
                  iCal
                </a>
              </dd>
            </dl>
          </div>
          <div tw="col-span-12 lg:col-span-10">
            <div tw="mb-8" className="wp-content">
              {content && parse(content)}
            </div>
            <Social title={title} pathname={location.pathname} />
          </div>
        </div>

        <div tw="mb-11">
          {moderation && moderation.length > 0 && (
            <div tw="mb-28">
              <h2 tw="mb-10 max-w-3xl text-3xl font-bold lg:mb-18 lg:text-5xl">
                Moderation
              </h2>
              <div tw="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4">
                {moderation.map((protagonist, index) => (
                  <div
                    key={protagonist.id}
                    tw="flex flex-col"
                    style={{ display: index > 5 ? "none" : "flex" }}
                  >
                    <Link to={protagonist.uri} tw="mb-4">
                      <figure>
                        <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-1/1">
                          {protagonist?.featuredImage && (
                            <GatsbyImage
                              image={
                                protagonist.featuredImage?.node?.localFile
                                  ?.childImageSharp?.gatsbyImageData
                              }
                              alt={protagonist.title}
                              tw="object-contain aspect-ratio-item"
                              load="lazy"
                            />
                          )}
                        </div>
                        <figcaption>
                          <p tw="text-2xl font-bold">{protagonist.title}</p>
                          <p tw="text-sm">{protagonist.member.jobtitle}</p>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
          {together && together.length > 0 && (
            <>
              <h2 tw="mb-10 max-w-3xl text-3xl font-bold lg:mb-18 lg:text-5xl">
                Mit
              </h2>
              <div tw="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4">
                {together.map((protagonist, index) => (
                  <div
                    key={protagonist.id}
                    tw="flex flex-col"
                    style={{ display: index > 5 ? "none" : "flex" }}
                  >
                    <Link to={protagonist.uri} tw="mb-4">
                      <figure>
                        <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-1/1">
                          {protagonist?.featuredImage && (
                            <GatsbyImage
                              image={
                                protagonist.featuredImage?.node?.localFile
                                  ?.childImageSharp?.gatsbyImageData
                              }
                              alt={protagonist.title}
                              tw="object-contain aspect-ratio-item"
                              load="lazy"
                            />
                          )}
                        </div>
                        <figcaption>
                          <p tw="text-2xl font-bold">{protagonist.title}</p>
                          <p tw="text-sm">{protagonist.member.jobtitle}</p>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

      </Container>
    </>
  )
}

export default Event

/**export const Head = props => {
  const propsWithTitle = {
    ...props,
    data: {
      ...props.data,
      seo: {
        seo: {
          ...props.data.seo.seo,
          title: props.data.event.title,
        },
      },
    },
  }

  return <HeadSeo {...propsWithTitle} />
}**/

export const pageQuery = graphql`
  query Event($id: String!) {
    event: wpEvent(id: { eq: $id }) {
      title
      content
      excerpt
      startDate
      endDate
      slug
      id
      eventInfo {
        info
      }
      eventsCategories {
        nodes {
          name
        }
      }
      protagonists {
        moderation {
          ... on WpProtagonist {
            id
            title
            featuredImage {
              node {
                id
                title
                filename
                slug
                uri
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            member {
              jobtitle
            }
          }
        }
        together {
          ... on WpProtagonist {
            id
            title
            featuredImage {
              node {
                id
                title
                filename
                slug
                uri
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            member {
              jobtitle
            }
          }
        }
      }
    }
  }
`
